import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import WelcomePage from './pages/WelcomePage';
import RegistrationPage from './pages/RegistrationPage'; 
import ResourcesPage from './pages/ResourcesPage.js';
import CommitteesPage from './pages/CommitteesPage.js';
import ComingSoonPage from './pages/ComingSoonPage.js';
import AboutUsPage from './pages/AboutUsPage.js';
import UNHRCbg from './pages/UNHRC_bg.js';
import DISECbg from './pages/DISEC_bg.js';
import UNSCbg from './pages/UNSC_bg.js';
import IPCbg from './pages/IPC_bg.js';
import CCCbg from './pages/CCC_bg.js';
import AIPPMbg from './pages/AIPPM_bg.js';
import UNODCbg from './pages/UNODC_bg.js';
import INTERPOLbg from './pages/INTERPOL_bg.js';

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<WelcomePage />} />
          <Route path="/register" element={<RegistrationPage />} />
          <Route path="/aboutus" element={< AboutUsPage />} />
          <Route path="/resources" element= {<ResourcesPage/>} />
          <Route path= "/committees" element= {<CommitteesPage />} />
          <Route path="/UNHRC_bg" element = {<UNHRCbg />}/>
          <Route path="/DISEC_bg" element = {<DISECbg />}/>
          <Route path="/UNSC_bg" element = {<UNSCbg />}/>
          <Route path="/IPC_bg" element = {<IPCbg />}/>
          <Route path="/CCC_bg" element = {<CCCbg />}/>
          <Route path="/AIPPM_bg" element = {<AIPPMbg />}/>
          <Route path="/UNODC_bg" element = {<UNODCbg />}/>
          <Route path="/INTERPOL_bg" element = {<INTERPOLbg />}/>
        </Routes>
      </div>
    </Router>
  );
}

export default App;
