
import React from 'react';
import { Link } from 'react-router-dom';
import Navbar from '../components/Navbar';
import '../styles/bg.css';

function UNODCbg() {
  return (
    <div className="pdf-page">
<div className='bg-nav'>
      <Navbar />
      </div>      <div className="pdf-container">
        <object data={require('./UNODC.pdf')} type="application/pdf" width="100%" height="100%">
          <p>Your browser does not support PDFs. Please download the PDF to view it: <a href={require('./UNODC.pdf')}>Download PDF</a>.</p>
        </object>
      </div>
    </div>
  );
}

export default UNODCbg;
