import React from 'react';
import { Link } from 'react-router-dom';
import Navbar from '../components/Navbar'
import '../styles/ResourcesPage.css';

function ResourcesPage() {
  return (
    <div classname='res-page-nav'>  
      <Navbar />
      <div className="resources-page">
        <h1 className="resources-title">BACKGROUND GUIDES</h1>
        <div className="button-container">
          <Link to="/UNHRC_bg" className="resource-button">UNHRC</Link>
          <Link to="/DISEC_bg" className="resource-button">DISEC</Link>
          <Link to="/UNSC_bg" className="resource-button">UNSC</Link>
          <Link to="/IPC_bg" className="resource-button">IPC</Link>
          <Link to="/CCC_bg" className="resource-button">CCC</Link>
          <Link to="/AIPPM_bg" className="resource-button">AIPPM</Link>
          <Link to="/UNODC_bg" className="resource-button">UNODC</Link>
          <Link to="/INTERPOL_bg" className="resource-button">INTERPOL</Link>
        </div>
      </div>
    </div>
  );
}

export default ResourcesPage;
