
import React from 'react';
import { Link } from 'react-router-dom';
import Navbar from '../components/Navbar';
import '../styles/bg.css';

function IPCbg() {
  return (
    <div className="pdf-page">
      <div className="pdf-container">
      <div className='bg-nav'>
      <Navbar />
      </div>        <object data={require('./IPC.pdf')} type="application/pdf" width="100%" height="100%">
          <p>Your browser does not support PDFs. Please download the PDF to view it: <a href={require('./IPC.pdf')}>Download PDF</a>.</p>
        </object>
      </div>
    </div>
  );
}

export default IPCbg;
