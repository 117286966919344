import React, { useState, useEffect } from "react";
import Navbar from "../components/Navbar";
import "../styles/AboutUsPage.css"; 

const images = [
  "images/zimage1.jpg",
  "images/zimage2.jpg",
  "images/zimage3.jpg",
  "images/zimage4.jpg",
  "images/zimage5.jpg",
  "images/zimage6.jpg",
  "images/zimage7.jpg",
  "images/zimage8.jpg",
  "images/zimagelast.jpg",
];

const AboutUsPage = () => {
  const fullHeadingText1 = "Who are we?";
  const fullHeadingText2 = "What is PeCon?";

  const [headingText1, setHeadingText1] = useState("");
  const [headingText2, setHeadingText2] = useState("");
  const [currentText, setCurrentText] = useState(fullHeadingText1);
  const [index, setIndex] = useState(0);
  const [isFirstHeadingComplete, setIsFirstHeadingComplete] = useState(false);

  useEffect(() => {
    const typeNextCharacter = () => {
      if (index < currentText.length) {
        if (currentText === fullHeadingText1) {
          setHeadingText1((prev) => prev + currentText.charAt(index));
        } else {
          setHeadingText2((prev) => prev + currentText.charAt(index));
        }
        setIndex((prev) => prev + 1);
      } else if (currentText === fullHeadingText1) {
        // Move to the second heading
        setCurrentText(fullHeadingText2);
        setIndex(0);
        setIsFirstHeadingComplete(true); // Mark the first heading as complete
      } else {
        clearInterval(intervalId); // Stop when both are done
      }
    };

    const intervalId = setInterval(typeNextCharacter, 100);

    return () => clearInterval(intervalId);
  }, [currentText, index]);

  // Slideshow logic
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) =>
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      );
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="about-us-container">
      <div className="aup-navbar">
        <Navbar />
      </div>
      <div className="slideshow-container">
        <img
          src={images[currentImageIndex]}
          alt="Slideshow"
          className="slideshow-image"
        />
      </div>

      <div className="text-box" style={{ alignSelf: "left" }}>
        <div className="text-half heading">
          <h2>{headingText1}</h2>
        </div>
        <div className="text-half ani">
          <p>
            The PES Model United Nations Society is one of the most prestigious
            clubs on campus. Founded in 2014, the MUNSoc, as we like to call it,
            has risen to heights unparalleled, dominating every conference we
            attend. The club hosts two annual events, The People's Conference
            and Diplomat Wars!!
          </p>
        </div>
      </div>

      <div className="text-box ani">
        <div className="text-half heading">
          {isFirstHeadingComplete && <h2>{headingText2}</h2>}
        </div>
        <div className="text-half">
          <p>
            The People's Conference is the PES MUN Society's flagship event. One
            of South India's largest MUNs, this conference is presently in its
            8th edition. With a whopping 8 committees and 500+ delegates,
            PeCon'24 is the go-to MUN this year!!
          </p>
        </div>
      </div>

      <div className="octext">
        <h1>Organizing Committee</h1>
      </div>

      <div className="auimage-container">
        <h3 className="auimage-title">Secretary General</h3>
        <div className="auimage-box">
          <img src="images/zsecgen.jpg" alt="Person" className="person-image" />
        </div>
        {/*<p className="person-name">Stuti Pathak</p>*/}
      </div>

      <div className="two-image-container">
        <div className="two-image-box">
          <h3 className="two-image-title">Deputy Secretary General</h3>
          <img src="images/zdsg.jpg" alt="Person 1" className="new-person-image" />
          {/*<p className="new-person-name">Name 1</p>*/}
        </div>

        <div className="two-image-box">
          <h3 className="two-image-title">Head of Finance</h3>
          <img src="images/zfinhead.jpg" alt="Person 2" className="new-person-image" />
          {/*<p className="new-person-name">Name 2</p>*/}
        </div>
      </div>

      <div className="team-image-container">
        <div className="team-image-box left-team">
          <div className="team-image-title">TECH</div>
          <img src="images/ztech.jpg" alt="Tech" className="team-image" />
        </div>
        <div className="team-image-box right-team">
          <div className="team-image-title">SPONSORSHIP</div>
          <img src="images/zsponsorship.jpg" alt="Sponsorship" className="team-image" />
        </div>
      </div>

      <div className="team-image-container">
        <div className="team-image-box left-team">
          <div className="team-image-title">DELEGATE AFFAIRS</div>
          <img src="images/zdelaffairs.jpg" alt="Tech" className="team-image" />
        </div>
        <div className="team-image-box right-team">
          <div className="team-image-title">FINANCE</div>
          <img src="images/zfinance.jpg" alt="Sponsorship" className="team-image" />
        </div>
      </div>

      <div className="team-image-container">
        <div className="team-image-box left-team">
          <div className="team-image-title">OPERATIONS</div>
          <img src="images/zoperations.jpg" alt="Tech" className="team-image" />
        </div>
        <div className="team-image-box right-team">
          <div className="team-image-title">LOGISTICS</div>
          <img src="images/zlogistics.jpg" alt="Sponsorship" className="team-image" />
        </div>
      </div>

      <div className="team-image-container">
        <div className="team-image-box left-team">
          <div className="team-image-title">OUTREACH</div>
          <img src="images/zoutreach.jpg" alt="Tech" className="team-image" />
        </div>
        <div className="team-image-box right-team">
          <div className="team-image-title">HOSPITALITY</div>
          <img src="images/zhospitality.jpg" alt="Sponsorship" className="team-image" />
        </div>
      </div>

      <div className="team-image-container">
        <div className="team-image-box left-team">
          <div className="team-image-title">PUBLIC RELATIONS</div>
          <img src="images/zpr.jpg" alt="Tech" className="team-image" />
        </div>
        <div className="team-image-box right-team">
          <div className="team-image-title">DESIGN</div>
          <img src="images/zdesign.jpg" alt="Sponsorship" className="team-image" />
        </div>
      </div>


    </div>
  );
};

export default AboutUsPage;


