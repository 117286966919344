
import React from 'react';
import  { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Navbar from '../components/Navbar.js';
import Countdown from '../components/Countdown.js';
/*import SpeechCard from '../components/SpeechCard.js';*/
import '../styles/WelcomePage.css';
import '../styles/RegistrationPage.css'

const WelcomePage = () => {
{/*
  const [isVisible, setIsVisible] = useState(false);
  const contentRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      { threshold: 0.1 } // Adjust this threshold as needed
    );

    if (contentRef.current) {
      observer.observe(contentRef.current);
    }

    return () => {
      if (contentRef.current) {
        observer.unobserve(contentRef.current);
      }
    };
  }, []);*/}
  
    const [isAnimationComplete, setIsAnimationComplete] = useState(false);
  
    useEffect(() => {
      const handleAnimationEnd = () => {
        setIsAnimationComplete(true);
      };
  
      const boxes = document.querySelectorAll('.comm-box');
      boxes.forEach(box => {
        box.addEventListener('animationend', handleAnimationEnd);
      });
  
      return () => {
        boxes.forEach(box => {
          box.removeEventListener('animationend', handleAnimationEnd);
        });
      };
    }, []);
  return (
    <div className="welcome-page">
      <div classname="speeches">
        <Navbar /> 
      <div/>
      <div className="content"> 
        <h1>DELEGATES TODAY,</h1>
        <h1>LEADERS TOMORROW</h1> 
        <br></br>   
        <br></br>
        <p>5-6 OCTOBER, 2024</p>
        <p>@PES UNIVERSITY</p>
        
      </div>

      <div className='welcome'>
        <p>
          
        </p>
        <Countdown />
      {/*
      </div>
      
      <div className='speeches'>
        <SpeechCard 
        imageSrc="c:\Users\abhay\OneDrive\Pictures\Screenshots\Abhay.JPG"
        name="John Doe"
        title="CEO, Company X"
        text="This is an example speech text. Replace this with actual content." />

      <SpeechCard 
        imageSrc="c:\Users\abhay\OneDrive\Pictures\Screenshots\Abhay.JPG"
        name="John Doe"
        title="CEO, Company X"
        text="This is an example speech text. Replace this with actual content." />

      <SpeechCard 
        imageSrc="c:\Users\abhay\OneDrive\Pictures\Screenshots\Abhay.JPG"
        name="John Doe"
        title="CEO, Company X"
        text="This is an example speech text. Replace this with actual content." />

      <SpeechCard 
        imageSrc="c:\Users\abhay\OneDrive\Pictures\AbhayKumar_ViceChair_UNGA.JPG  "
        name="John Doe"
        title="CEO, Company X"
        text="This is an example speech text. Replace this with actual content." />
      */}
      </div>
      <div className="rectangular-component-wp">
        <a href="https://drive.google.com/file/d/1kxd9zj8qC0suphxVGfyOA5qRv3NcRGJQ/view?usp=sharing" target="_blank" rel="noopener noreferrer" style={{ color: '#E0C491', textDecoration:'none', justifyContent: "center", textAlign: "center" }}>
          <p>EQUITY POLICY</p>
        </a>      
      </div>
      
      {/*<div className="rectangular-component-wp">
      <a href="https://docs.google.com/forms/d/e/1FAIpQLSevWNORn7DpWYERn9UMopMrJ6L6v9JKXDB6RqmohIYByUN5YQ/closedform" target="_blank" rel="noopener noreferrer" style={{ color: '#E0C491', textDecoration: 'none' }}>
          <p>DELEGATION REGISTRATION FORM</p>
        </a> 
      </div>
      <div className="rectangular-component-wp">
        <a href="https://docs.google.com/spreadsheets/d/1DHt8hPKtrL4kcLfZnOA2Hh3howAw7i3tpOzKbe7Ws1c/edit?usp=sharing" target="_blank" rel="noopener noreferrer" style={{ color: '#E0C491', textDecoration:'none', justifyContent: 'center', textAlign:'center' }}>
          <p>COUNTRY MATRIX</p>
        </a>      
      </div>*/}

      <div className="comm-wp-ani" style={{paddingTop: '50px'}}>
      EQUITY TEAM
    </div>
    <ul className='some-new-shit'>
      <li>Sai Dyuthi : +91 9606682874</li>
      <li>Amogh S : +91 7338438241</li>
      <li>Vishal Nagaraj : +91 9108164733</li>
      <li style={{paddingBottom: "50px"}}>Preetham : +91 9573557857</li>
    </ul>
      

    <div className="comm-wp-ani" style={{padding: '0px'}}>
      DRESS CODE
    </div>
    <ol className="some-new-shit" style={{textAlign:"center", width: "100%", }}>
    <li style={{padding: "10px"}}><strong>•</strong> Delegates need to dress up in <strong>Western Formals</strong>, and in a manner appropriate to an educational setting.</li>
    <li style={{padding: "10px"}}><strong>•</strong> Skirts, dresses, jeans, sport shoes and other informal attire are not permitted.</li>
    <li style={{padding: "10px"}}><strong>•</strong> Attire must cover the knees at all times.</li>
    <li style={{padding: "10px"}}><strong>•</strong> Delegates of <strong>AIPPM</strong> may opt to wear Indian formals on the days of the conference.</li>
    <li style={{padding: "10px"}}><strong>Failing to comply with this rule may result in the delegate being debarred from attending the conference.</strong></li>
    </ol>


      <div className="comm-wp-ani" >
      COMMITTEES</div>
      <div className="comm-box-container" >
        {/* Row 1 */}
        <div className={`comm-box ${isAnimationComplete ? 'restart-animation' : ''}`}>
          <p>UNSC</p>
        </div>
        <div className={`comm-box ${isAnimationComplete ? 'restart-animation' : ''}`}>
          <p>UNHRC</p>
        </div>
        <div className={`comm-box ${isAnimationComplete ? 'restart-animation' : ''}`}>
          <p>DISEC</p>
        </div>
        <div className={`comm-box ${isAnimationComplete ? 'restart-animation' : ''}`}>
          <p>UNODC</p>
        </div>
        
        {/* Row 2 */}
        <div className={`comm-box ${isAnimationComplete ? 'restart-animation' : ''}`}>
          <p>AIPPM</p>
        </div>
        <div className={`comm-box ${isAnimationComplete ? 'restart-animation' : ''}`}>
          <p>CCC</p>
        </div>
        <div className={`comm-box ${isAnimationComplete ? 'restart-animation' : ''}`}>
          <p>INTERPOL</p>
        </div>
        <div className={`comm-box ${isAnimationComplete ? 'restart-animation' : ''}`}>
          <p>IPC</p>
        </div>
      </div>
      <div className='spaceee'>
        <hr color='#04352A'></hr>
      </div>
    </div>
    
    
    </div>
  );
};

export default WelcomePage;
